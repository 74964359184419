<template>
  <div>
    <app-breadcrumb :items="breadcrumbs" class="mb-24px"/>
    <router-view class="mb-40px" style="min-height: calc(100vh - 400px)"/>
    <div>
      <div class="text-dark fs-md fw-500 mb-24px">{{ $t('text.contact_info') }}:</div>
      <div class="d-flex aic contact-info-wrapper">
        <span v-for="(item, idx) in contact" :key="idx" class="d-flex aic contact-info-item" style="margin-right: 36px;">
          <component :is="item.icon" stroke="#467BFF"/>
          <a v-if="item.type === 'email'" :href="`mailto:${item.value}`" class="text-grey ml-12px">{{ item.value }}</a>
          <a v-else-if="item.type === 'phone'" :href="`tel:${item.value}`" class="text-grey ml-12px">{{ item.value }}</a>
          <span v-else class="text-grey ml-12px">{{ item.value }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@/components/elements/AppBreadcrumb';
import MailOpenIcon from '@/assets/icons/mailOpenIcon';
import PhoneIcon from '@/assets/icons/phoneIcon';
import UserCircleIcon from '@/assets/icons/userCircleIcon';

export default {
  components: {
    AppBreadcrumb,
    MailOpenIcon,
    PhoneIcon,
    UserCircleIcon
  },
  computed: {
    breadcrumbs() {
      return [
        {name: this.$t('navigation.home'), route: 'Dashboard'},
        {name: this.$t('label.contact'), route: 'ContactList'}
      ];
    },
    contact() {
      return [
        {value: 'Idriss Sisaïd', icon: 'UserCircleIcon', type: 'text'},
        {value: '+33 6 69 52 15 30', icon: 'PhoneIcon', type: 'phone'},
        {value: 'support@osol.fr', icon: 'MailOpenIcon', type: 'email'},
      ];
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-info-wrapper {
  .contact-info-item {
    * {
      line-height: 14px;
    }
  }
}
</style>
