<template>
  <div class="auth-wrapper">
    <div class="header-logo text-center">
      <router-link :to="{name:'Login'}" class="d-inline-block">
        <login-img/>
      </router-link>
    </div>
    <div class="auth-form-wrapper">
      <router-view/>
    </div>
    <div class="support-wrapper">
      <span>{{ $t('label.contact_for_request') }}: </span>
      <a href="mailto:support@osol.fr">support@osol.fr</a>
    </div>
    <div class="language-switcher">
      <a-radio-group :default-value="defaultLanguage" button-style="solid" @change="switchLanguage">
        <a-radio-button value="en">English</a-radio-button>
        <a-radio-button value="fr">Français</a-radio-button>
      </a-radio-group>
    </div>
  </div>
</template>

<script>
import LoginImg from '@/assets/text-logo.svg';

export default {
  components: {
    LoginImg
  },
  data: () => ({
    defaultLanguage: null
  }),
  beforeMount() {
    this.defaultLanguage = localStorage.getItem('language') || 'en';
  },
  methods: {
    switchLanguage(e) {
      localStorage.setItem('language', e.target.value);
      this.$root.$i18n.locale = e.target.value;
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-wrapper::v-deep {
  min-height: 100vh;
  background: #F7F8FA;

  .header-logo {
    padding: 40px 0 0;
    margin-bottom: 170px;
    text-align: center;

    a {
      position: absolute;
      left: calc(50% - 72px);
      z-index: 1;
    }
  }

  .auth-form-wrapper {
    margin: 0 200px;
    max-width: 330px;
  }

  .title {
    margin-bottom: 40px;
    font-size: 32px;
    white-space: nowrap;
  }

  .support-wrapper {
    margin: 80px 200px 0 200px;
    color: #132540;
    font-size: 15px;
  }

  .language-switcher {
    margin: 16px 200px 0 200px;

    .ant-radio-button-wrapper {
      height: auto;
      padding: 8px 16px;
      border: 1px solid #467BFF;
      font-size: 12px;
      line-height: 14px;

      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &.ant-radio-button-wrapper-checked {
        background: #467BFF;
      }

      &:not(.ant-radio-button-wrapper-checked) {
        color: #467BFF;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-height: 820px) {
  .auth-wrapper::v-deep {
    .header-logo {
      padding: 40px 0 0;
      margin-bottom: 110px;
    }

    .auth-form-wrapper {
      margin: 0 120px;

      .title {
        margin-bottom: 24px;
        font-size: 28px;
      }
    }

    .support-wrapper {
      margin: 60px 120px 0;
    }

    .language-switcher {
      margin: 16px 120px 0;

      .ant-radio-button-wrapper {
        padding: 8px 16px;
      }
    }
  }
}
</style>
